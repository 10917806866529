import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import Tilty from "react-tilty";


const BannerOne = () => {
    return (
        <div className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">Full stack SAAS Enterprise &amp; mobile apps.</h1>
                                <span className="subtitle">We provide full stack Software as a Service solutions, mobile development, and cloud solutions for enterprise.</span>
                            </AnimationOnScroll>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ul className="list-unstyled shape-group-19">
                            <li className="shape shape-1">
                                <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble"/>
                            </li>
                            <li className="shape shape-2">
                                <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">

            </ul>
        </div>
    )
}

export default BannerOne;