import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">Transforming Ideas into Digital Excellence</h2>
                                <p>With over a five decades of experience in digital transformation, we specialize in turning
                                    complex business challenges into elegant, efficient solutions. Our team of expert
                                    developers and designers work seamlessly to deliver technology that not only meets
                                    your current needs but scales with your future growth.</p>
                                <p>From enterprise-grade applications to cutting-edge cloud solutions, we pride
                                    ourselves on delivering robust, secure, and innovative technology solutions that
                                    drive real business value. Our commitment to quality and client success has made us
                                    a trusted partner for businesses across industries.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get in touch!</h3>
                            <FormOne/>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
                                                   alt="Bubble"/></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                                                   alt="line"/></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                                                   alt="line"/></li>
            </ul>
        </section>
    )
}

export default AboutOne;