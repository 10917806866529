import React from 'react';
import { Link } from 'react-router-dom';
import {
	MdPhoneIphone,
	MdWeb,
	MdAccountBalance,
	MdCloud,
	MdStorage,
	MdDesktopMac
} from 'react-icons/md';

const serviceData = [
	{
		icon: MdPhoneIphone,
		title: "Mobile Development",
		description: "Transform your business with cutting-edge mobile solutions. Our expert team delivers enterprise-grade applications that combine stunning design with powerful functionality. From native apps to cross-platform solutions, we ensure your mobile presence drives real business value and exceptional user experiences.",
	},
	{
		icon: MdWeb,
		title: "Web Development",
		description: "Launch your digital presence with our enterprise-ready web development services. We build scalable, high-performance web applications that grow with your business. Our solutions combine modern architecture with robust security, ensuring your platform delivers consistent value to your customers.",
	},
	{
		icon: MdAccountBalance,
		title: "Quickbooks Support",
		description: "Streamline your financial operations with our comprehensive QuickBooks expertise. Our certified consultants provide end-to-end support for setup, optimization, and troubleshooting. We ensure your financial systems run smoothly, allowing you to focus on growing your business.",
	},
	{
		icon: MdCloud,
		title: "IaaS",
		description: "Leverage the power of Infrastructure as a Service with our enterprise-grade cloud solutions. We design, implement, and manage scalable infrastructure that adapts to your business needs. Our IaaS solutions provide the flexibility and reliability your organization demands.",
	},
	{
		icon: MdStorage,
		title: "Enterprise Cloud",
		description: "Accelerate your digital transformation with our comprehensive enterprise cloud services. We architect and deploy robust cloud solutions that enhance operational efficiency, reduce costs, and drive innovation. Our expertise ensures seamless migration and optimal performance.",
	},
	{
		icon: MdDesktopMac,
		title: "Desktop Support",
		description: "Maximize productivity with our responsive desktop support services. Our experienced team provides rapid resolution for all your technical challenges, ensuring minimal downtime and optimal performance. We keep your systems running smoothly so you can focus on what matters most.",
	}
];

const ServicePropOne = ({ colSize, serviceStyle, itemShow, marginTop }) => {
	const topMargin = (index) => {
		if (marginTop === "yes") {
			if (index === 0) return "mt--200 mt_md--0";
			if (index === 1) return "mt--100 mt_md--0";
			return "mt--0";
		}
		return "";
	};

	return (
		<>
			{serviceData.slice(0, itemShow).map((data, index) => (
				<div className={`${colSize} ${topMargin(index)}`} key={index}>
					<div className={`services-grid ${serviceStyle}`}>
						<div className="thumbnail flex items-center justify-center">
							<data.icon size={64} className="text-primary"/>
						</div>
						<div className="content">
						<h5 className="title">
								<Link to={`/service-details/${data.title.toLowerCase().replace(/\s+/g, '-')}`}>
									{data.title}
								</Link>
							</h5>
							<p>{data.description}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default ServicePropOne;